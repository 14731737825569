<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />
    <div class="todo-app-list">

      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">

        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="PenToolIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              :value="searchQuery"
              :disabled="true"
              @input="updateRouteQuery"
            />
          </b-input-group>
        </div>
      </div>

      <!-- Problem Solving List -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="todo-task-list-wrapper list-group scroll-area"
      >
        <draggable
          v-show="!isLoading"
          v-model="problemSolvings"
          handle=".draggable-task-handle"
          tag="ul"
          class="todo-task-list media-list"
        >
          <li
            v-for="problemSolving in problemSolvings"
            :key="problemSolving._id"
            class="todo-item"
            :class="{ 'completed': problemSolving.completed }"
            @click="handleProblemSolvingClick(problemSolving)"
          >
            <feather-icon
              icon="MoreVerticalIcon"
              class="draggable-task-handle d-inline"
            />
            <div class="todo-title-wrapper">
              <div class="todo-title-area">
                <div class="title-wrapper">
                  <b-form-checkbox
                    :checked="problemSolving.completed"
                    @click.native.stop
                    @change="updateProblemSolvingIsCompleted(problemSolving)"
                  />
                  <span class="todo-title">{{ problemSolving.title }}</span>
                </div>
              </div>
              <div class="todo-item-action">
                <!-- <div class="badge-wrapper mr-1">
                  <b-badge
                    v-for="tag in problemSolving.tags"
                    :key="tag"
                    pill
                    :variant="`light-${resolveTagVariant(tag)}`"
                    class="text-capitalize"
                  >
                    {{ $t(`domain.${tag}`) }}
                  </b-badge>
                </div> -->
                <!-- <small :class="`text-nowrap mr-1 ${setDateColor(problemSolving.dueDate, problemSolving.completed)}`">
                  {{ formatDate(problemSolving.dueDate, { month: 'short', day: 'numeric'}) }}
                </small> -->
                <b-avatar
                  v-if="problemSolving.leader"
                  size="32"
                  variant="light-primary"
                  :text="avatarText(problemSolving.leader.name)"
                />
                  <!-- :src="problemSolving.assignee.avatar"    optional attribute inside b-avatar-->
                <b-avatar
                  v-else
                  size="32"
                  variant="light-secondary"
                >
                  <feather-icon
                    icon="UserIcon"
                    size="16"
                  />
                </b-avatar>
              </div>
            </div>
          </li>
        </draggable>
        <div
          class="no-results"
          :class="{'show': !isLoading && !problemSolvings.length}"
        >
          <h5>{{ $t('message.no_problem_solvings_found') }}</h5>
        </div>
        <loading
          v-if="isLoading"
          :active="true" 
          :is-full-page="false"
          :color="colors.primary"
        />
        <!-- <div
          class="no-results"
          :class="{'show': isLoading}"
        >
          <h5>{{ $t('message.loading_improvements') }}</h5>
        </div> -->
      </vue-perfect-scrollbar>
    </div>

    <!-- Problem Solving Handler -->
    <problem-solving-handler-sidebar
      v-model="isProblemSolvingHandlerSidebarActive"
      :problemSolving="problemSolving"
      :clear-problem-solving-data="clearProblemSolvingData"
      handlerId="problem-solving"
      @remove-problemSolving="removeProblemSolving"
      @add-problemSolving="addProblemSolving"
      @update-problemSolving="updateProblemSolving"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <problem-solving-left-sidebar
        :is-problem-solving-handler-sidebar-active.sync="isProblemSolvingHandlerSidebarActive"
        :class="{'show': mqShallShowLeftSidebar}"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>
  </div>
</template>

<script>
import store from '@/store'
import { ref, watch, computed, onMounted } from '@vue/composition-api'
import { BFormInput, BInputGroup, BInputGroupPrepend, BDropdown, BDropdownItem, BFormCheckbox, BBadge, BAvatar } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import { formatDate, avatarText } from '@core/utils/filter'
import { useRouter } from '@core/utils/utils'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ProblemSolvingLeftSidebar from './ProblemSolvingLeftSidebar.vue'
import problemSolvingStoreModule from './problemSolvingStoreModule'
import ProblemSolvingHandlerSidebar from './ProblemSolvingHandlerSidebar.vue'
import useCommonTodo from '@/views/apps/todo/useCommonTodo'
import useNotifications from '@/composables/useNotifications'
import i18n from '@/libs/i18n'
import awsConnection from '@/views/habit/aws'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import useCommon from "@/views/organization/useCommon"
import { colors } from '@/constants'
import realmConnection from '@/views/habit/realm'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BBadge,
    BAvatar,
    draggable,
    VuePerfectScrollbar,
    Loading,

    // App SFC
    ProblemSolvingLeftSidebar,
    ProblemSolvingHandlerSidebar,
  },
  setup() {
    const PROBLEM_SOLVING_STORE_MODULE_NAME = 'problem-solving'

    const userData = store.state?.userStore?.userData;
    const clientId = userData.role !== "admin" ? userData.client.$oid : null;
    const workerId = userData.worker_id.$oid;
    const username = userData.username;
    const isLoading = ref(true)
    const categoryFilter = ref("all")
    // const tagFilter = ref("all")
    const now = new Date()

    const { resolveTagVariant, resolveAvatarVariant, getEmailImprovementTemplate, formatEmailDate } = useCommonTodo()
    const { showSuccessMessage, showErrorMessage } = useNotifications()
    const { singleUpload, sendEmail } = awsConnection()
    const { handleError } = useCommon()
    const { createItem, createItems, updateItem, updateItems, getItemsWithAggregate, ObjectId } = realmConnection()

    // Register module
    if (!store.hasModule(PROBLEM_SOLVING_STORE_MODULE_NAME)) store.registerModule(PROBLEM_SOLVING_STORE_MODULE_NAME, problemSolvingStoreModule)

    // Reset store states for filters
    store.commit('problem-solving/SET_SELECTED_LOCATIONS', [])
    store.commit('problem-solving/SET_SELECTED_WORKERS', [])
    
    const { route, router } = useRouter()

    const routeParamsQuery = route.value.query.originId

    // Fetch problemSolvings and get metadata on mounted
    onMounted(async () => {
      fetchProblemSolvings()

      if (routeParamsQuery) {
        try {
          const query = { _id: ObjectId(routeParamsQuery) }

          const pipeline = [
            { $match: query },
            {
              $lookup: {
                from: 'worker',
                localField: 'leader',
                foreignField: '_id',
                pipeline: [ { $project: { name: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ],
                as: 'leader'
              }
            },
            {
              $lookup: {
                from: 'improvement',
                localField: 'improvements',
                foreignField: '_id',
                pipeline: [
                  { $project: { note: 1, origin: 1, origin_id: 1, description: 1, dueDate: 1, assignee: 1 } },
                  {
                    $lookup: {
                      from: 'worker',
                      localField: 'assignee',
                      foreignField: '_id',
                      pipeline: [ { $project: { name: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ],
                      as: 'assignee',
                    },
                  },
                  { $addFields: { _id: { $toString: "$_id" }, assignee: { $arrayElemAt: ["$assignee", 0] } } }
                ],
                as: 'improvements'
              }
            },
            { $addFields: { _id: { $toString: "$_id" }, leader: { $arrayElemAt: ["$leader", 0] } } },
          ]
        
          const items = await getItemsWithAggregate({ collection: 'problem_solving', pipeline })
          if (!items?.[0]) throw new Error('Item not found')
          
          problemSolving.value = items[0]
          isProblemSolvingHandlerSidebarActive.value = true
        } catch (error) {
          console.log(error)
          showErrorMessage(i18n.t('message.no_problem_solvings_found'))
        }
      }
    })

    // UnRegister on leave (can't unregister because of 'Add improvement' button in navbar)
    // onUnmounted(() => {
    //   if (store.hasModule(PROBLEM_SOLVING_STORE_MODULE_NAME)) store.unregisterModule(PROBLEM_SOLVING_STORE_MODULE_NAME)
    // })

    // const routeSortBy = computed(() => route.value.query.sort)
    // const routeQuery = computed(() => route.value.query.q)
    const routeParams = computed(() => route.value.params)
    
    watch(routeParams, val => {
      isLoading.value = true
      categoryFilter.value = val.filter ? val.filter : "all"
      // tagFilter.value = val.tag ? val.tag : "all"
      fetchProblemSolvings()
    })

    const locationFilter = computed(() => store.state[PROBLEM_SOLVING_STORE_MODULE_NAME].selectedLocations)
    const workerFilter = computed(() => store.state[PROBLEM_SOLVING_STORE_MODULE_NAME].selectedWorkers)

    watch([locationFilter, workerFilter], () => {
      isLoading.value = true
      fetchProblemSolvings()
    })

    const problemSolvings = ref([])

    let blankProblemSolving = {
      client_id: clientId,
      title: '',
      leader: {_id: workerId, name: username},
      deleted: false,
      completed: false,
      important: false,
      improvements: [],
    }
    const problemSolving = ref(JSON.parse(JSON.stringify(blankProblemSolving)))
    const clearProblemSolvingData = () => {
      problemSolving.value = JSON.parse(JSON.stringify(blankProblemSolving))
    }

    const fetchProblemSolvings = async () => {
      try {
        const initialQuery = {
          client_id: ObjectId(clientId),
          deleted: { $ne: true },
        }

        // Category
        if (categoryFilter.value !== "all") {
          initialQuery[categoryFilter.value] = true
        }

        // Leader
        if (workerFilter.value.length > 0) {
          initialQuery.leader = { $in: workerFilter.value.map(e => ObjectId(e)) }
        }

        const finalQuery = {}

        // Location
        if (locationFilter.value.length) {
          finalQuery['leader.locations'] = { $in: locationFilter.value.map(e => ObjectId(e)) }
        }

        // User logged as supervisor
        if (userData.role === 'supervisor') {
          finalQuery.$or = [
            { 'leader.supervisors': ObjectId(userId) },
            { 'leader._id': userId },
          ]
        }

        const pipeline = [
          { $match: initialQuery },
          {
            $lookup: {
              from: 'worker',
              localField: 'leader',
              foreignField: '_id',
              pipeline: [
                { $project: { name: 1, locations: 1, supervisors: 1 } },
                { $addFields: { _id: { $toString: "$_id" } } }
              ],
              as: 'leader'
            }
          },
          { $match: finalQuery },
          {
            $lookup: {
              from: 'improvement',
              localField: 'improvements',
              foreignField: '_id',
              pipeline: [
                { $project: { note: 1, description: 1, dueDate: 1, completed: 1, deleted: 1, imageKey: 1, assignee: 1, metadata: 1 } },
                {
                  $lookup: {
                    from: 'worker',
                    localField: 'assignee',
                    foreignField: '_id',
                    pipeline: [ { $project: { name: 1, email: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ],
                    as: 'assignee',
                  },
                },
                { $addFields: { _id: { $toString: "$_id" }, assignee: { $arrayElemAt: ["$assignee", 0] } } }
              ],
              as: 'improvements'
            }
          },
          { $addFields: { _id: { $toString: "$_id" }, leader: { $arrayElemAt: ["$leader", 0] } } },
        ]

        const items = await getItemsWithAggregate({ collection: 'problem_solving', pipeline })

        problemSolvings.value = items
      } catch (error) {
        console.log(error)
        handleError({ error, defaultMessage: i18n.t('message.err_problem_solving_list') })
      } finally {
        isLoading.value = false
      }
    }

    const addProblemSolving = async val => {
      try {
        const payload = {
          ...val,
          client_id: ObjectId(val.client_id),
          leader: ObjectId(val.leader._id),
        }

        // Create improvements
        if (payload.improvements?.length) {
          // Upload images to AWS and then store image keys in an array
          const uploadImagePromises = payload.improvements.map(i => {
            if (!i.imgData) return null
            const { fileInfo, destinationFolder } = i.imgData
            return new Promise((resolve, reject) => {
              singleUpload(fileInfo, destinationFolder)
                .then((key) => resolve(key))
                .catch((err) => {
                  console.log(err)
                  resolve(null)
                })
            })
          })
          const imageKeys = await Promise.all(uploadImagePromises)

          const improvementsToCreate = payload.improvements.map((i, index) => {
            const improvement = {
              client_id: ObjectId(i.client_id),
              note: i.note,
              description: i.description,
              assignee: i.assignee ? ObjectId(i.assignee._id) : null,
              dueDate: i.dueDate ? new Date(`${i.dueDate.slice(0, 10)} 12:00:00`) : null,
              deleted: false,
            }
            if (i.imgData) improvement.imageKey = imageKeys[index]
            if (i.metadata?.length) {
              improvement.metadata = i.metadata.map(m => ({
                name: m.name,
                type: m.type,
                answer: m.name === "instance_leader" ? val.leader.name : m.answer,
              }))
            }
            return improvement
          })

          const { insertedIds: newImprovementIds } = await createItems({ collection: 'improvement', payload: improvementsToCreate })
          payload.improvements = newImprovementIds
        } else {
          delete payload.improvements
        }

        const { insertedId } = await createItem({ collection: 'problem_solving', payload })
        if (!insertedId) throw new Error('Item not created')
        
        showSuccessMessage(i18n.t('message.problem_solving_created'))
                  
        // Refetch problem solvings to get updated list
        fetchProblemSolvings()

        // Send email to assignee with the details of the commitments created
        if (val.improvements?.length) {
          val.improvements.forEach(i => {
            if (i.assignee?.email) {
              const subject = i18n.t('message.commitment_assigned')
              let bodyData = {
                name: i.assignee?.name,
                title: i.note,
                creator: username,
                dueDate: formatEmailDate(i.dueDate),
                description: i.description?.replace(/<[^>]*>/g, ''),
                commitmentFunctionality: true,
                fromProblemSolving: true
              }
              if (i.metadata?.length) {
                i.metadata.forEach(e => {
                  if (e.name === "creation_date") e.answer = formatEmailDate(e.answer)
                  Object.assign(bodyData, {[e.name]: e.answer})
                })
              }
              const body = getEmailImprovementTemplate(bodyData)
              
              sendEmail([i.assignee.email], subject, body)
                .then((response) => {
                  if (response.MessageId) showSuccessMessage(i18n.t('message.email_send_commitment_success'))
                })
                .catch((err) => {
                  console.log(err)
                  showErrorMessage(i18n.t('message.email_send_commitment_error'))
                })
            }
          })
        }

        // Update improvements of the problem solving to add origin and origin_id from problem solving
        try {
          if (payload.improvements?.length) {
            const updateQuery = { _id: { $in: payload.improvements } }
            const action = { $set: { origin_id: insertedId, origin: "problem_solving" } }
            await updateItems({ collection: 'improvement', query: updateQuery, action })
          }
        } catch (error) {
          console.log(error)
          showErrorMessage(i18n.t('message.improvement_update_error'))
        }
      } catch (error) {
        console.log(error)
        showErrorMessage(i18n.t('message.problem_solving_error'))
      }
    }

    const updateProblemSolving = async (problemSolvingData, action, commitmentsToUpdate = [], commitmentsToDelete = []) => {
      try {
        const query = { _id: ObjectId(problemSolvingData._id) }
        const payload = {
          title: problemSolvingData.title || '',
          leader: ObjectId(problemSolvingData.leader._id),
          completed: problemSolvingData.completed || false,
          deleted: problemSolvingData.deleted || false,
          important: problemSolvingData.important || false,
        }

        // Create improvements
        if (problemSolvingData.improvements?.length) {
          // Upload images to AWS and then store image keys in an array
          const uploadImagePromises = problemSolvingData.improvements.map(i => {
            if (i._id || !i.imgData?.fileInfo) return null
            const { fileInfo, destinationFolder } = i.imgData
            return new Promise((resolve, reject) => {
              singleUpload(fileInfo, destinationFolder)
                .then((key) => resolve(key))
                .catch((err) => {
                  console.log(err)
                  resolve(null)
                })
            })
          })
          const imageKeys = await Promise.all(uploadImagePromises)

          const existingIds = []
          const improvementsToCreate = []

          problemSolvingData.improvements.forEach((i, index) => {
            if (i._id) existingIds.push(ObjectId(i._id))
            else {
              // Create new improvement
              const improvement = {
                client_id: ObjectId(i.client_id),
                note: i.note,
                description: i.description,
                assignee: i.assignee ? ObjectId(i.assignee._id) : null,
                dueDate: i.dueDate ? new Date(`${i.dueDate.slice(0, 10)} 12:00:00`) : null,
                deleted: false,
                origin: "problem_solving",
                origin_id: ObjectId(problemSolvingData._id),
              }
              if (i.imgData) improvement.imageKey = imageKeys[index]
              if (i.metadata?.length) {
                improvement.metadata = i.metadata.map(m => ({
                  name: m.name,
                  type: m.type,
                  answer: m.name === "instance_leader" ? problemSolvingData.leader.name : m.answer,
                }))
              }
              improvementsToCreate.push(improvement)
            }
          })

          let newImprovementIds = []
          if (improvementsToCreate.length) {
            const { insertedIds } = await createItems({ collection: 'improvement', payload: improvementsToCreate })
            newImprovementIds = insertedIds
          }
          payload.improvements = [...existingIds, ...newImprovementIds]
        } else {
          payload.improvements = []
        }

        const realmAction = { $set: payload }

        await updateItem({ collection: 'problem_solving', query, action: realmAction })
        
        if (action !== "complete") showSuccessMessage(i18n.t('message.problem_solving_updated'))
                  
        // Refetch problem solvings to get updated list
        fetchProblemSolvings()

        // Send email to assignee with the details of the commitments created and updated
        const allImprovements = problemSolvingData.improvements
          ? [...problemSolvingData.improvements, ...commitmentsToDelete]
          : [...commitmentsToDelete]

        if (allImprovements.length) {
          for (const i of allImprovements) {
            // If improvement is not new or flagged for update/delete or assigne doesn't have email then continue (don't send email)
            if (i._id && !commitmentsToUpdate.find(e => e._id === i._id) && !commitmentsToDelete.find(e => e._id === i._id) || !i.assignee?.email) {
              continue
            }

            const updatedState = !i._id
              ? "add"
              : commitmentsToDelete.find(e => e._id === i._id)
                ? "delete"
                : "update"

            const subject = updatedState === "add"
              ? i18n.t('message.commitment_assigned')
              : updatedState === "delete"
                ? i18n.t('message.commitment_deleted')
                : i18n.t('message.commitment_updated')

            let bodyData = {
              name: i.assignee?.name,
              title: i.note,
              creator: username,
              dueDate: formatEmailDate(i.dueDate),
              description: i.description?.replace(/<[^>]*>/g, ''),
              commitmentFunctionality: true,
              fromProblemSolving: true,
              updatedState,
              completed: i.completed,
              deleted: i.deleted,
              category: 'assignee'
            }
            if (i.metadata?.length) {
              i.metadata.forEach(e => {
                if (e.name === "creation_date") e.answer = formatEmailDate(e.answer)
                Object.assign(bodyData, {[e.name]: e.answer})
              })
            }
            const body = getEmailImprovementTemplate(bodyData)
            
            sendEmail([i.assignee.email], subject, body)
              .then((response) => {
                if (response.MessageId) showSuccessMessage(i18n.t('message.email_send_commitment_success'))
              })
              .catch((err) => {
                console.log(err)
                showErrorMessage(i18n.t('message.email_send_commitment_error'))
              })
          }
        }
      } catch (error) {
        console.log(error)
        showErrorMessage(i18n.t('message.problem_solving_error'))
      }
    }

    const removeProblemSolving = async (problemSolvingData, commitmentsToDelete) => {
      const action = { $set: { deleted: true } }

      try {
        // Delete commitments associated with problem solving
        if (problemSolvingData.improvements?.length) {
          const updateQuery = { _id: { $in: problemSolvingData.improvements.map(e => {
            if (!e._id) return null
            return ObjectId(e._id)
          }) } }

          await updateItems({ collection: 'improvement', query: updateQuery, action })
        }

        // Delete commitments already flagged for deletion
        if (commitmentsToDelete?.length) {
          const updateQuery = { _id: { $in: commitmentsToDelete.map(e => ObjectId(e._id)) } }
          
          await updateItems({ collection: 'improvement', query: updateQuery, action })
        }
      } catch (error) {
        console.log(error)
        showErrorMessage(i18n.t('message.improvement_update_error'))
      }

      try {
        // Delete problem solving
        const query = { _id: ObjectId(problemSolvingData._id) }

        await updateItem({ collection: 'problem_solving', query, action })

        showSuccessMessage(i18n.t('message.problem_solving_deleted'))

        fetchProblemSolvings()
      } catch (error) {
        console.log(error)
        showErrorMessage(i18n.t('message.problem_solving_delete_error'))
      }
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const isProblemSolvingHandlerSidebarActive = ref(false)

    // Search Query
    const searchQuery = ref("")
    // const searchQuery = ref(routeQuery.value)
    // watch(routeQuery, val => {
    //   searchQuery.value = val
    // })
    // eslint-disable-next-line no-use-before-define
    // watch([searchQuery, sortBy], () => fetchProblemSolvings())
    const updateRouteQuery = () => {}
    // const updateRouteQuery = val => {
    //   const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

    //   if (val) currentRouteQuery.q = val
    //   else delete currentRouteQuery.q

    //   router.replace({ name: route.name, query: currentRouteQuery })
    // }

    const handleProblemSolvingClick = problemSolvingData => {
      problemSolving.value = problemSolvingData
      isProblemSolvingHandlerSidebarActive.value = true
    }

    // Single Problem Solving isCompleted update
    const updateProblemSolvingIsCompleted = problemSolvingData => {
      // eslint-disable-next-line no-param-reassign
      problemSolvingData.completed = !problemSolvingData.completed
      updateProblemSolving(problemSolvingData, "complete")
    }

    // const setDateColor = (problemSolvingDate, completed) => {
    //   if (completed) return "text-success"
    //   const date = new Date(problemSolvingDate)
    //   if (date < now) return "text-danger"
    //   if (date > nowPlusOneWeek) return "text-muted"
    //   return "text-warning"
    // }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      problemSolving,
      problemSolvings,
      removeProblemSolving,
      addProblemSolving,
      updateProblemSolving,
      clearProblemSolvingData,
      searchQuery,
      fetchProblemSolvings,
      perfectScrollbarSettings,
      updateRouteQuery,
      // resetSortAndNavigate,
      isLoading,
      // setDateColor,

      // UI
      resolveTagVariant,
      resolveAvatarVariant,
      isProblemSolvingHandlerSidebarActive,

      // Click Handler
      handleProblemSolvingClick,

      // Filters
      formatDate,
      avatarText,

      // Single Problem Solving isCompleted update
      updateProblemSolvingIsCompleted,

      // Left Sidebar Responsive
      mqShallShowLeftSidebar,
      routeParamsQuery,

      colors    
    }
  },
}
</script>

<style lang="scss" scoped>
.draggable-task-handle {
position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    cursor: move;

    .todo-task-list .todo-item:hover & {
      visibility: visible;
    }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
