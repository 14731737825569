import { ref, watch } from '@vue/composition-api'
import realmConnection from '@/views/habit/realm'

export default function useProblemSolvingHandler(props, emit) {
  // ------------------------------------------------
  // problemSolvingLocal
  // ------------------------------------------------
  const problemSolvingLocal = ref(JSON.parse(JSON.stringify(props.problemSolving.value)))
  const resetProblemSolvingLocal = () => {
    problemSolvingLocal.value = JSON.parse(JSON.stringify(props.problemSolving.value))
  }
  watch(props.problemSolving, () => {
    resetProblemSolvingLocal()
  })

  const { updateItem, updateItems, ObjectId } = realmConnection()

  const commitmentsToUpdate = ref([])
  const commitmentsToDelete = ref([])

  const onSubmit = () => {
    const problemSolvingData = JSON.parse(JSON.stringify(problemSolvingLocal))

    // * If event has id => Edit Event
    // Emit event for add/update event
    props.problemSolving.value._id = props.routeParamsQuery ? props.routeParamsQuery : props.problemSolving.value._id
    if (props.problemSolving.value._id) emit('update-problemSolving', problemSolvingData.value, "update", commitmentsToUpdate.value, commitmentsToDelete.value)
    else emit('add-problemSolving', problemSolvingData.value)

    // Update commitments
    if (commitmentsToUpdate.value.length) {
      commitmentsToUpdate.value.forEach(e => {
        const query = { _id: ObjectId(e._id) }
        const payload = {
          note: e.note || '',
          description: e.description || '',
          assignee: ObjectId(e.assignee._id),
          dueDate: e.dueDate ? new Date(`${e.dueDate.slice(0, 10)} 12:00:00`) : null
        }
        const action = { $set: payload }

        try {
          updateItem({ collection: 'improvement', query, action })
        } catch (error) {
          console.log(error)
        }
      })
    }

    // Delete commitments
    if (commitmentsToDelete.value.length) {
      const query = { _id: { $in: commitmentsToDelete.value.map(e => ObjectId(e._id)) } }
      const action = { $set: { deleted: true } }
      
      try {
        updateItems({ collection: 'improvement', query, action })
      } catch (error) {
        console.log(error)
      }
    }

    // Close sidebar
    emit('update:is-problem-solving-handler-sidebar-active', false)
  }

  return {
    problemSolvingLocal,
    resetProblemSolvingLocal,
    onSubmit,
    commitmentsToUpdate,
    commitmentsToDelete,
  }
}
