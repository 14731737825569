import { ref, watch } from '@vue/composition-api'
import store from '@/store'

export default function useCommitmentSidebar(props, emit) {
  // ------------------------------------------------
  // taskLocal
  // ------------------------------------------------
  const commitmentLocal = ref(JSON.parse(JSON.stringify(props.commitment.value)))
  const resetCommitmentLocal = () => {
    commitmentLocal.value = JSON.parse(JSON.stringify(props.commitment.value))
    commitmentLocal.value.description = commitmentLocal.value.description 
      ? commitmentLocal.value.description.replace(/<[^>]*>/g, '')
      : ''
    imgData.value = commitmentLocal.value.imgData || {}
  }
  watch(props.commitment, () => {
    resetCommitmentLocal()
  })

  const onSubmit = () => {
    const commitmentData = JSON.parse(JSON.stringify(commitmentLocal))

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (imgData.value.fileInfo) commitmentData.value.imgData = imgData.value
    if (props.commitmentIndex.value !== -1) emit('update-commitment', commitmentData.value, props.commitmentIndex.value)
    else {
      emit('add-commitment', commitmentData.value)
    }

    // Close sidebar
    emit('update:is-commitment-sidebar-active', false)
  }

  // Image Upload
  const userData = store.state?.userStore?.userData

  const compressor = ref(null)
  const imgData = ref({})

  const uploadImage = () => {
    compressor.value.$el.click()
  }

  const getImgCompressed = (obj) => {
    imgData.value = {
      fileInfo: obj.compressed,
      destinationFolder: `${userData.client.$oid}/improvements`
    }
  }

  return {
    commitmentLocal,
    resetCommitmentLocal,
    onSubmit,

    // Image upload
    uploadImage,
    getImgCompressed,
    compressor,
    imgData,
  }
}
