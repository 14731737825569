// import axios from '@axios'
// import { getProblemSolvings, addProblemSolving, updateProblemSolving } from '@/@core/queries/problem-solving'
// import { addImprovement, updateImprovement } from '@/@core/queries/improvements'
// import store from '@/store'

export default {
  namespaced: true,
  state: {
    selectedLocations: [],
    selectedWorkers: [],
  },
  getters: {},
  mutations: {
    SET_SELECTED_LOCATIONS(state, val) {
      state.selectedLocations = val
    },
    SET_SELECTED_WORKERS(state, val) {
      state.selectedWorkers = val
    },
  },
  actions: {
    // fetchProblemSolvings(ctx, {category, tag, locations, workers}) {
    //   const getUserData = store.state?.userStore?.userData
    //   const userId = getUserData.worker_id != null ? getUserData.worker_id.$oid : null
    //   const query = {
    //     client_id: (getUserData.role != 'admin') ? { _id: getUserData?.client?.$oid} : null,
    //     deleted_ne: true
    //   }

    //   // Category
    //   if (category !== "all") {
    //     query[category] = true
    //     if (category === "deleted") delete query.deleted_ne
    //   }

    //   // Tag
    //   // if (tag !== "all") query.tags_in = tag

    //   // Location
    //   if (locations.length) {
    //     const locationsFilterQuery = locations.map(e => ({ _id: e }))
    //     query.leader = { locations_in: locationsFilterQuery }
    //   }

    //   // Leader
    //   if (workers.length) {
    //     if (query.leader) query.leader._id_in = workers
    //     else query.leader = { _id_in: workers }
    //   }

    //   // User logged as supervisor
    //   if (getUserData.role === 'supervisor') {
    //     query.OR = [{ leader: { supervisors_in: {_id: userId} } }, { leader: {_id: userId} }]
    //   }
      
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post('/graphql', {
    //         query: getProblemSolvings,
    //         variables: {
    //           query,
    //           limit: 10000,
    //           sortBy: "_ID_ASC"
    //         },
    //       })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    // async addProblemSolving(ctx, problemSolvingData) {
    //   const leaderName = problemSolvingData.leader?.name

    //   if (problemSolvingData.leader) {
    //     problemSolvingData.leader = { link: problemSolvingData.leader._id }
    //   }

    //   if (problemSolvingData.improvements?.length) {
    //     // Upload images to AWS and then store image keys in an array
    //     const uploadImagePromises = problemSolvingData.improvements.map(i => {
    //       if (!i.imgData) return null
    //       const {fileInfo, destinationFolder} = i.imgData
    //       return new Promise((resolve, reject) => {
    //         problemSolvingData.singleUpload(fileInfo, destinationFolder)
    //           .then((key) => resolve(key))
    //           .catch((err) => {
    //             console.log(err)
    //             resolve(null)
    //           })
    //       })
    //     })
    //     const imageKeys = await Promise.all(uploadImagePromises)

    //     // Set improvements property
    //     problemSolvingData.improvements = {
    //       create: problemSolvingData.improvements.map((i, index) => {
    //         const improvement = {
    //           client_id: i.client_id,
    //           note: i.note,
    //           description: i.description,
    //           assignee: i.assignee ? { link: i.assignee._id } : null,
    //           dueDate: i.dueDate ? new Date(`${i.dueDate.slice(0, 10)} 12:00:00`) : null,
    //           deleted: false,
    //         }
    //         if (i.imgData) improvement.imageKey = imageKeys[index]
    //         if (i.metadata?.length) {
    //           improvement.metadata = i.metadata.map(m => ({
    //             name: m.name,
    //             type: m.type,
    //             answer: m.name === "instance_leader" ? leaderName : m.answer,
    //           }))
    //         }
    //         return improvement
    //       })
    //     }
    //   } else {
    //     delete problemSolvingData.improvements
    //   }

    //   delete problemSolvingData.singleUpload

    //   const query = {
    //     query: addProblemSolving,
    //     variables: { data: problemSolvingData },
    //   }

    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post('/graphql', query)
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    // async updateProblemSolving(ctx, problemSolvingData) {
    //   const leaderName = problemSolvingData.leader?.name

    //   if (problemSolvingData.leader) {
    //     problemSolvingData.leader = { link: problemSolvingData.leader._id }
    //   }

    //   if (problemSolvingData.improvements) {
    //     // Upload images to AWS and then store image keys in an array
    //     const uploadImagePromises = problemSolvingData.improvements.map(i => {
    //       if (i._id || !i.imgData?.fileInfo) return null
    //       const { fileInfo, destinationFolder } = i.imgData
    //       return new Promise((resolve, reject) => {
    //         problemSolvingData.singleUpload(fileInfo, destinationFolder)
    //           .then((key) => resolve(key))
    //           .catch((err) => {
    //             console.log(err)
    //             resolve(null)
    //           })
    //       })
    //     })
    //     const imageKeys = await Promise.all(uploadImagePromises)

    //     const improvementCreationPromises = problemSolvingData.improvements.map((i, index) => {
    //       if (i._id) return i._id

    //       // Create new improvement
    //       const newImprovement = {
    //         client_id: i.client_id,
    //         assignee: i.assignee ? { link: i.assignee._id } : null,
    //         note: i.note,
    //         description: i.description,
    //         dueDate: i.dueDate ? new Date(`${i.dueDate.slice(0, 10)} 12:00:00`) : null,
    //         deleted: false,
    //         origin: "problem_solving",
    //         origin_id: problemSolvingData._id,
    //       }
    //       if (i.imgData) newImprovement.imageKey = imageKeys[index]
    //       if (i.metadata?.length) {
    //         newImprovement.metadata = i.metadata.map(m => ({
    //           name: m.name,
    //           type: m.type,
    //           answer: m.name === "instance_leader" ? leaderName : m.answer,
    //         }))
    //       }

    //       return new Promise((resolve, reject) => {
    //         axios
    //           .post('/graphql', {
    //             query: addImprovement,
    //             variables: { data: newImprovement }
    //           })
    //           .then(({data}) => {
    //             if (data.errors) throw new Error(data.errors[0].message)
    //             resolve(data.data.insertOneImprovement._id)
    //           })
    //           .catch((error) => {
    //             console.log(error)
    //             resolve(null)
    //           })
    //       })
    //     })

    //     const ids = await Promise.all(improvementCreationPromises)

    //     const filteredIds = ids.filter(e => !!e)

    //     problemSolvingData.improvements = {
    //       link: filteredIds
    //     }
    //   } else {
    //     delete problemSolvingData.improvements
    //   }

    //   delete problemSolvingData.singleUpload

    //   const query = {
    //     query: updateProblemSolving,
    //     variables: {
    //       query: { _id: problemSolvingData._id },
    //       data: problemSolvingData,
    //     },
    //   }

    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post('/graphql', query)
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    // removeProblemSolving(ctx, { problemSolvingData, commitmentsToDelete }) {
    //   // Delete commitments associated with problem solving
    //   if (problemSolvingData.improvements?.length) {
    //     problemSolvingData.improvements.forEach(i => {
    //       if (i._id) {
    //         axios
    //           .post('/graphql', {
    //             query: updateImprovement,
    //             variables: { query: { _id: i._id }, data: { deleted: true } }
    //           })
    //           .then(() => { })
    //           .catch((error) => {
    //             console.log(error)
    //           })
    //       }
    //     })
    //   }

    //   // Delete commitments already flagged for deletion
    //   if (commitmentsToDelete?.length) {
    //     commitmentsToDelete.forEach(i => {
    //       axios
    //         .post('/graphql', {
    //           query: updateImprovement,
    //           variables: { query: { _id: i._id }, data: { deleted: true } }
    //         })
    //         .then(() => {})
    //         .catch((error) => {
    //           console.log(error)
    //         })
    //     })
    //   }

    //   const query = {
    //     query: updateProblemSolving,
    //     variables: {
    //       query: { _id: problemSolvingData._id },
    //       data: { deleted: true },
    //     },
    //   }

    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post('/graphql', query)
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
  },
}
