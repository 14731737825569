<template>
  <div>
    <b-sidebar
      id="sidebar-commitment"
      sidebar-class="sidebar-lg"
      :visible="isCommitmentSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-commitment-sidebar-active', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ commitmentIndex !== -1 ? $t('commitment') : $t('add_commitment') }}
          </h5>
          <div>
            <feather-icon
              v-show="commitmentIndex !== -1"
              icon="TrashIcon"
              class="cursor-pointer"
              v-b-modal="handlerId + commitmentIndex"
            />
            <b-modal
              :id="handlerId + commitmentIndex"
              centered
              no-close-on-backdrop
              modal-class="modal-danger"
              ok-variant="danger"
              cancel-variant="outline-secondary"
              :title="$t('message.confirm_action')"
              :ok-title="$t('message.delete')"
              :cancel-title="$t('message.cancel')"
              @ok="$emit('remove-commitment', commitmentLocal, commitmentIndex); hide();"
            >
              {{ $t('message.confirm_delete_commitment') }}
            </b-modal>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- Root Cause -->
            <validation-provider
              #default="validationContext"
              :name="$t('label.root_cause')"
              rules="required"
            >
              <b-form-group
                :label="$t('label.root_cause')"
                label-for="commitment-root-cause"
              >
                <b-form-input
                  id="commitment-root-cause"
                  v-model="commitmentLocal.description"
                  :state="getValidationState(validationContext)"
                  trim
                  :placeholder="$t('placeholder.root_cause')"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Action -->
            <validation-provider
              #default="validationContext"
              :name="$t('label.action')"
              rules="required"
            >
              <b-form-group
                :label="$t('label.action')"
                label-for="commitment-action"
              >
                <b-form-input
                  id="commitment-action"
                  v-model="commitmentLocal.note"
                  :state="getValidationState(validationContext)"
                  trim
                  :placeholder="$t('placeholder.action')"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Responsible -->
            <validation-provider
              #default="validationContext"
              :name="$t('label.responsible')"
              rules="required"
            >
              <b-form-group
                :label="$t('label.responsible')"
                label-for="responsible"
              >
                <v-select
                  v-model="commitmentLocal.assignee"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="commitmentFunctionality ? workersTotalNotMapped : workersSupervisedNotMapped"
                  label="name"
                  class="assignee-selector"
                  input-id="responsible"
                  :placeholder="$t('placeholder.responsible')"
                >
                  <template #option="{ name }">
                    <span class="ml-50 d-inline-block align-middle"> {{ name }}</span>
                  </template>

                  <template #selected-option="{ name }">
                    <span class="ml-50 d-inline-block align-middle"> {{ name }}</span>
                  </template>
                </v-select>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- due Date -->
            <validation-provider
              #default="validationContext"
              :name="$t('label.due_date')"
              rules="required"
            >

              <b-form-group
                :label="$t('label.due_date')"
                label-for="due-date"
              >
                <flat-pickr
                  v-model="commitmentLocal.dueDate"
                  class="form-control"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Metadata -->
            <!-- <div v-if="commitmentLocal.metadata && commitmentLocal.metadata.length">
              <b-form-group
                v-for="metadata in commitmentLocal.metadata" :key="metadata._id"
                :label="metadata.type === 'datepicker' ? $t(`metadata.${metadata.name}`) : null"
                :label-for="$t(`metadata.${metadata.name}`)"
                description=""
              >
                <flat-pickr
                  v-if="metadata.type === 'datepicker'"
                  v-model="metadata.answer"
                  class="form-control"
                />
              </b-form-group>
            </div> -->

            <!-- Image -->
            <!-- Open Image -->
            <b-form-group
              v-if="commitmentLocal._id && commitmentLocal.imageKey"
              :label="$t('Image')"
              label-for="image"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="info"
                @click="openImage(commitmentLocal.imageKey)"
              >
                {{ $t('message.open') }}
              </b-button>
            </b-form-group>

            <!-- Upload Image -->
            <b-form-group
              v-if="!commitmentLocal._id"
              :label="$t('Image')"
              label-for="image"
            >
              <b-button
                class="mr-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="info"
                @click="uploadImage()"
              >
                {{ $t('message.upload') }}
              </b-button>
              <image-compressor
                style="display: none"
                :done="getImgCompressed"
                ref="compressor"
              />
              <small v-if="imgData.fileInfo">
                {{imgData.fileInfo.name}}
              </small>
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ commitmentIndex !== -1 ? $t('message.update') : $t('message.add') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                {{ $t('message.cancel') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BModal, VBModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { toRefs, onMounted, watch, ref } from '@vue/composition-api'
import useCommitmentSidebar from './useCommitmentSidebar'
import useCommon from '@/views/organization/useCommon'
import ImageCompressor from '@/views/habit/ImageCompressor.vue';

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BModal,

    // 3rd party packages
    vSelect,
    flatPickr,
    ImageCompressor,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  model: {
    prop: 'isCommitmentSidebarActive',
    event: 'update:is-commitment-sidebar-active',
  },
  props: {
    isCommitmentSidebarActive: {
      type: Boolean,
      required: true,
    },
    commitment: {
      type: Object,
      required: true,
    },
    commitmentIndex: {
      type: Number,
      required: true,
    },
    clearCommitmentData: {
      type: Function,
      required: true,
    },
    handlerId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const {
      commitmentLocal,
      resetCommitmentLocal,
      onSubmit,

      // Image upload
      uploadImage,
      getImgCompressed,
      compressor,
      imgData,
    } = useCommitmentSidebar(toRefs(props), emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetCommitmentLocal, props.clearCommitmentData)

    const { getWorkersSupervisedForDropDown, workersSupervisedNotMapped, getWorkersTotalForDropDown, workersTotalNotMapped, openImage } = useCommon()

    const commitmentFunctionality = JSON.parse(localStorage.getItem("clientData") || '{}').commitment_functionality

    onMounted(async () => {
      if (commitmentFunctionality) getWorkersTotalForDropDown("notMapped")
      else getWorkersSupervisedForDropDown("notMapped")
    })

    return {
      // Add New
      commitmentLocal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      // Workers dropdown
      workersSupervisedNotMapped,
      workersTotalNotMapped,

      // Image upload
      uploadImage,
      getImgCompressed,
      compressor,
      imgData,
      openImage,

      // Others
      commitmentFunctionality,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}
</style>
